import React from "react";

const experienceContent = [
  {
    year: "   2024 - Present",
    position: " Business Owner & Developer",
    compnayName: "Gulley Systems, LLC.",
    details: `  I formed my own software & web development business in the spring of 2024. I create customized web and desktop
    solutions for my clients to help them satisfy their business needs.`,
  },
  {
    year: "2022 - Present",
    position: " Technical Support Engineer",
    compnayName: "Digital Visions, LLC.",
    details: ` I work for the support department at Digital Visions, LLC. We specialize in security technology and access control.
    I've had the opportunity to refine my skills in the cyber security space during my employment here. This has helped strengthen 
    my application security standards and practices.`,
  },
  {
    year: "2019 - 2022",
    position: "Full Stack Web Developer",
    compnayName: "Graphic Information Systems",
    details: ` I was a full stack web developer at GIS where I designed, developed, and maintained a fully customized web app solution 
    to centralize order management data, streamline company processes, automate tasks, and increase the overall company productivity, 
    increasing company revenue.`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
