import React from "react";

const educationContent = [
  {
    year: "2024",
    degree: "ASSOCIATE'S DEGREE",
    institute: "DeVry UNIVERSITY",
    details: `  I received my associate's degree in Information Technology and Networking in the fall of 2024 at DeVry University.`,
  },
  {
    year: "2024",
    degree: "CERTIFICATE",
    institute: "DeVry UNIVERSITY",
    details: `I received my Programming Essentials Certificate at DeVry University in the spring of 2024.`,
  },
  {
    year: "2016",
    degree: "DIPLOMA ",
    institute: "Lakota East High School",
    details: `I graduated from Lakota East High School in 2016. During my time in school, I focused on and passed many Computer Science courses.`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
