import Image1 from "../../assets/img/portfolio/project-ecjs.jpg";
import Image2 from "../../assets/img/portfolio/kryptokola-1.jpg";
import Image3 from "../../assets/img/portfolio/ngc.jpg";
import Image4 from "../../assets/img/portfolio/socialmirage.jpg";
import Image5 from "../../assets/img/portfolio/gs.jpg";
import Image6 from "../../assets/img/portfolio/hg.jpg";
import Image7 from "../../assets/img/portfolio/jms.jpg";
import Image8 from "../../assets/img/portfolio/dvsp.jpg";

const PortfolioData = [
  {
    id: 1,
    type: "East Coast Jet Skis",
    image: Image1,
    tag: ["website"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Website",
        client: "East Coast Jet Skis",
        language: "HTML, CSS, JavaScript, PHP",
        preview: "www.eastcoastjetskis.com",
        link: "https://www.eastcoastjetskis.com/",
      },
    ],
  },
  {
    id: 3,
    type: "KryptoKola",
    image: Image2,
    tag: ["website"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Website",
        client: "KryptoKola",
        language: " HTML, CSS, Javascript, PHP",
        preview: "www.kryptokola.com",
        link: "https://www.kryptokola.com",
      },
    ],
  },
  {
    id: 2,
    type: "Netta's Glamour Couture",
    image: Image3,
    tag: ["website"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "Website",
        client: "Netta's Glamour Couture",
        language: "Wordpress",
        preview: "www.nettasglamourcouture.com",
        link: "https://www.nettasglamourcouture.com",
      },
    ],
  },
  {
    id: 4,
    type: "Social Mirage",
    image: Image4,
    tag: ["website"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Website",
        client: "Social Mirage",
        language: " HTML, CSS, JavaScript, jQuery, PHP",
        preview: "www.socialmirage.us",
        link: "https://www.socialmirage.us",
      },
    ],
  },
  {
    id: 5,
    type: "Gulley Systems, LLC.",
    image: Image5,
    tag: ["website"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "Website",
        client: "Gulley Systems, LLC.",
        language: "HTML, CSS, JavaScript, PHP",
        preview: "www.gulleysystems.com",
        link: "https://www.gulleysystems.com",
      },
    ],
  },
  {
    id: 6,
    type: "Hunter Gulley's Photos",
    image: Image6,
    tag: ["website"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Website",
        client: "Hunter Gulley",
        language: "HTML, CSS, JavaScript, PHP",
        preview: "www.huntergulley.com",
        link: "https://www.huntergulley.com",
      },
    ],
  },
  {
    id: 7,
    type: "TrackPoint",
    image: Image7,
    tag: ["web_app"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Web Application",
        client: "[Redacted]",
        language: "HTML, CSS, JavaScript, jQuery, PHP",
        preview: "Not Publicly Available",
        link: "Contact me for more information.",
      },
    ],
  },
  {
    id: 8,
    type: "SyncPoint",
    image: Image8,
    tag: ["web_app"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "Web Application",
        client: "[Redacted]",
        language: "HTML, CSS, JavaScript, jQuery, PHP",
        preview: "Not Publicly Available",
        link: "Contact me for more information.",
      },
    ],
  },
];

export default PortfolioData;
